import React, { lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // 🔥 Use HashRouter
import { AnimatePresence } from 'framer-motion';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import PageWrapper from './components/PageWrapper';

// Lazy load pages
const Home = lazy(() => import('./components/Home'));
const About = lazy(() => import('./components/About'));
const Projects = lazy(() => import('./components/Projects'));
const Contact = lazy(() => import('./components/Contact'));

function App() {
  return (
    <Router>
      <Header />
      <div className="main-content">
        <AnimatePresence mode="wait">
          <Suspense fallback={<div className="loading">Loading...</div>}>
            <Routes>
              <Route path="/" element={<PageWrapper><Home /></PageWrapper>} />
              <Route path="/about" element={<PageWrapper><About /></PageWrapper>} />
              <Route path="/projects" element={<PageWrapper><Projects /></PageWrapper>} />
              <Route path="/contact" element={<PageWrapper><Contact /></PageWrapper>} />
            </Routes>
          </Suspense>
        </AnimatePresence>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
